<template>
  <h1 class="text-xl font-bold my-8">顧客の請求及び支払い履歴</h1>
  <div>
    <select v-model="selected" @change="onchange()">
      <option disabled value="">選択して下さい</option>
      <option
        v-for="(customer, index) in customers"
        :key="index"
        :value="customer.id"
      >
        {{ customer.name }}
      </option>
    </select>
    <h1>customer id: {{ selected }}</h1>
  </div>
  <div class="billing-list text-xs">
    <table class="text-left">
      <thead>
        <tr class="border-b-2">
          <th class="pr-4">請求日</th>
          <th class="pr-4">pi</th>
          <th class="pr-4">pi_date</th>
          <th class="pr-4">金額(税抜き)</th>
          <th class="pr-4">税金</th>
          <th class="pr-4">通貨</th>
          <th class="pr-4">サービス</th>
          <th class="pr-4">期間</th>
          <th class="pr-4">数量</th>
          <th class="pr-4">価格</th>

          <th class="pr-4">confirm_result</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(billing, index) in flatBillings"
          :key="index"
          class="border-b-2"
        >
          <template v-if="index === billing.startIndex">
            <td class="pr-4" :rowspan="billing.rowspan">
              {{ billing.billingDate }}
            </td>
            <td class="pr-4" :rowspan="billing.rowspan">
              {{ billing.pi }}
            </td>
            <td class="pr-4" :rowspan="billing.rowspan">
              {{ billing.piDate }}
            </td>

            <td class="pr-4" :rowspan="billing.rowspan">
              {{ billing.totalPrice }}
            </td>
            <td class="pr-4" :rowspan="billing.rowspan">{{ billing.tax }}</td>
            <td class="pr-4" :rowspan="billing.rowspan">
              {{ billing.currency }}
            </td>
          </template>

          <td class="pr-4">{{ truncate(billing.name) }}</td>
          <td class="pr-4">{{ billing.term }}</td>
          <td class="pr-4">{{ billing.amount }}</td>
          <td class="pr-4">{{ billing.price }}</td>

          <template v-if="index === billing.startIndex">
            <td class="pr-4" :rowspan="billing.rowspan">
              <template
                v-for="(confirm, index) in billing.confirms"
                :key="index"
              >
                {{ confirm.date }} ({{ confirm.result }}) <br />
              </template>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { http } from '@/plugins/ky'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/actions'

function getResult(result, authorized) {
  if (result !== true) {
    return authorized === 'canceled' ? 'キャンセル' : 'キャプチャ待ち'
  }

  return '完了'
}

export default {
  data() {
    return {
      selected: '',
      customers: [
        {
          id: '',
          name: '',
        },
      ],
      flatBillings: [
        {
          startIndex: 0,
          rowspan: 0,
          billingDate: '',
          piDate: '',
          pi: '',
          totalPrice: '',
          name: '',
          term: '',
          amount: 0,
          price: 0,
          tax: 0,
          currency: '',
          confirms: [
            {
              result: '',
              date: '',
            },
          ],
        },
      ],
    }
  },

  computed: {
    truncate: function () {
      return (value) => {
        const length = 15
        const ommision = '...'
        if (value.length <= length) {
          return value
        }
        return value.substring(0, length) + ommision
      }
    },
  },
  mounted: function () {
    // console.log(333333, this.$route.query.customerId)
    http
      .get('admin/customers')
      .then(async (r) => {
        const result = await r.json()
        console.log(result)
        this.$data.customers = result
        this.$data.selected = this.$route.query.customerId
        this.onchange()
      })
      .catch(async ({ response, message }) => {
        const { dispatch } = useStore()

        dispatch(ActionTypes.SetNotice, {
          message,
        })

        console.log(response, message)
      })
  },
  methods: {
    onchange() {
      http
        .get('admin/customers/billings', {
          searchParams: {
            customerId: this.$data.selected,
          },
        })
        .then(async (r) => {
          const result = await r.json()
          let startIndex = 0
          const customersBillings = []

          result.forEach((billing, index) => {
            if (billing.services.length > 0) {
              billing.services.forEach((service) => {
                customersBillings.push({
                  startIndex,
                  rowspan: billing.services.length,
                  billingDate: `${billing.billingDate.substr(
                    0,
                    4
                  )}/${billing.billingDate.substr(4, 2)}`,
                  piDate: billing.piDate.split('+')[0],
                  pi: billing.pi,
                  totalPrice: billing.totalPrice,
                  name: service.name,

                  term: `${service.startDate.split('+')[0]} - ${
                    service.expireDate.split('+')[0]
                  }`,
                  amount: service.amount,
                  price: service.total,
                  tax: billing.tax,
                  currency: billing.currency,
                  confirms: billing.confirms.map((confirm) => {
                    const { result, confirmDate, authorized } = confirm
                    return {
                      date: confirmDate.split('+')[0],
                      result: getResult(result, authorized),
                    }
                  }),
                })
              })
            } else {
              customersBillings.push({
                startIndex,
                rowspan: 1,
                billingDate: `${billing.billingDate.substr(
                  0,
                  4
                )}/${billing.billingDate.substr(4, 2)}`,
                piDate: billing.piDate.split('+')[0],
                pi: billing.pi,
                totalPrice: billing.totalPrice,
                name: '見つからない',
                term: '',
                amount: 0,
                price: 0,
                tax: 0,
                currency: '',
                confirms: billing.confirms.map((confirm) => {
                  const { result, confirmDate, authorized } = confirm
                  return {
                    date: confirmDate.split('+')[0],
                    result: getResult(result, authorized),
                  }
                }),
              })
            }

            startIndex +=
              billing.services.length === 0 ? 1 : billing.services.length
          })

          console.log(customersBillings)
          this.$data.flatBillings = customersBillings
        })
        .catch(async ({ response, message }) => {
          const { dispatch } = useStore()

          dispatch(ActionTypes.SetNotice, {
            message,
          })

          console.log(response, message)
        })
    },
  },
}
</script>

<style scoped lang="postcss">
.billing-list {
  width: 2000px;
}
</style>
